<script lang="ts">
  import { MenuItem } from "@rgossiaux/svelte-headlessui";
  import { Check } from "lucide-svelte";

  export let href: string = "";
  export let selected: boolean = false;
  let clazz: string = "";
  export let style: string = "";
  export { clazz as class };
  export let newTab = false;

  // if false can't click on the item
  export let disabled: boolean = false;
</script>

<MenuItem let:active as="div">
  {#if href}
    <a
      href={disabled ? undefined : href}
      class="menu-item {clazz}"
      class:disabled
      {style}
      class:active
      class:selected
      target={newTab ? "_blank" : ""}
      rel={newTab ? "noopener noreferrer" : ""}
      on:click
    >
      <slot name="icon" />
      <span class="label"><slot /></span>
      {#if selected}
        <Check size={20} class="text-primary ml-auto" />
      {/if}
    </a>
  {:else}
    <button
      {disabled}
      class="menu-item {clazz}"
      class:disabled
      {style}
      class:active
      class:selected
      on:click
    >
      <slot name="icon" />
      <span class="label"><slot /></span>
      {#if selected}
        <Check size={20} class="text-primary ml-auto" />
      {/if}
    </button>
  {/if}
</MenuItem>

<style lang="postcss">
  .menu-item {
    @apply min-w-full px-2 py-1.5 rounded-md flex items-center gap-x-2 text-left;
  }

  .disabled {
    @apply opacity-50 cursor-default;
  }

  .menu-item.selected {
    @apply bg-primary/10;
  }

  .menu-item.active:not(.selected) {
    @apply bg-white/5;
  }

  .label {
    @apply flex-grow text-sm text-white whitespace-nowrap overflow-hidden text-ellipsis;
  }
</style>
